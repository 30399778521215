(function() {
	var el = document.createElement("script");
	el.setAttribute("defer", "");
	el.setAttribute("src", "//www.google.com/recaptcha/api.js");
	document.body.appendChild(el);
})();

document.querySelectorAll(".g-recaptcha").forEach(function (element, index) {
	element.dataset.sitekey = document.currentScript.dataset.sitekey;
	element.dataset.size = "invisible";
	var functionName = "recaptchaSubmit" + index;
	window[functionName] = function (token) {
		element.closest("form").submit();
	};
	element.dataset.callback = functionName;
});
